import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import {cn, buildImageObj} from '../lib/helpers';
import {format, parseISO} from 'date-fns';
import {imageUrlFor} from '../lib/image-url'

export default function ProjectPreview(props) {
  return (
    <Card sx={{ maxWidth: 445, maxHeight: 400 }}>
      <CardActionArea style={{width:'100%'}}>
        <CardMedia
          component="img"
          height="250"
          image={imageUrlFor(buildImageObj(props.photo)).url()}
          alt={props}
        />
      </CardActionArea>
      <CardContent>
        <Typography gutterBottom variant="h7" component="div" style={{fontSize: '18px'}}>
          <b>{props.title}</b>
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
          {format(parseISO(props.publishedAt), 'dd/MM/yyyy')}
        </Typography> */}
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" href={`/product/${props.slug.current}`}>
          Δειτε περισσοτερα...
        </Button>
      </CardActions>
    </Card>
  );
}

